import http from "./http";

const listOrdersWithPagination = async (filters) => {
  if (filters.limit)
    return http
      .index("orders", filters)
      .then((json) => ({ orders: json }))
      .catch(async (e) => ({ orders: null, error: await e }));

  const orders = [];
  let keepRunning = true;
  let pagination = 1;
  while (keepRunning) {
    const { data } = await http
      .index("orders", { ...filters, limit: 1000, pagination })
      .then((json) => json);

    orders.push(data);

    if (data.length < 1000) keepRunning = false;

    pagination++;
  }

  return { orders: orders.flat() };
};

export default (orderId) => ({
  async index(filters) {
    return listOrdersWithPagination(filters);
  },
  async get() {
    return http
      .get("orders", orderId)
      .then((json) => ({ order: json }))
      .catch(async (e) => ({ order: null, error: await e }));
  },
  async create(data) {
    return http
      .post("orders", data)
      .then((json) => ({ order: json }))
      .catch(async (e) => ({ order: null, error: await e }));
  },
  async update(data) {
    return http
      .put("orders", orderId, data)
      .then((json) => ({ order: json }))
      .catch(async (e) => ({ order: null, error: await e }));
  },
  async delete() {
    return http
      .delete("orders", orderId)
      .then((json) => ({ order: json }))
      .catch(async (e) => ({ order: null, error: await e }));
  },
  async sum(filters) {
    return http
      .index("orders/sum", filters)
      .then((json) => ({ sum: json }))
      .catch(async (e) => ({ sum: null, error: await e }));
  },
  async count(filters) {
    return http
      .index("orders/count", filters)
      .then((json) => ({ count: json }))
      .catch(async (e) => ({ count: null, error: await e }));
  },
  Products: (productId) => ({
    async index(filters) {
      return http
        .index(`orders/${orderId}/products`, filters)
        .then((json) => ({ products: json }))
        .catch(async (e) => ({ products: null, error: await e }));
    },
    async get() {
      return http
        .get(`orders/${orderId}/products`, productId)
        .then((json) => ({ product: json }))
        .catch(async (e) => ({ product: null, error: await e }));
    },
    async create(data) {
      return http
        .post(`orders/${orderId}/products`, data)
        .then((json) => ({ product: json }))
        .catch(async (e) => ({ product: null, error: await e }));
    },
    async update(data) {
      return http
        .put(`orders/${orderId}/products`, productId, data)
        .then((json) => ({ product: json }))
        .catch(async (e) => ({ product: null, error: await e }));
    },
    async delete() {
      return http
        .delete(`orders/${orderId}/products`, productId)
        .then((json) => ({ product: json }))
        .catch(async (e) => ({ product: null, error: await e }));
    },
  }),
  Observations: (obsId) => ({
    async index(filters) {
      return http
        .index(`orders/${orderId}/obs`, filters)
        .then((json) => ({ observations: json }))
        .catch(async (e) => ({ observations: null, error: await e }));
    },
    async get() {
      return http
        .get(`orders/${orderId}/obs`, obsId)
        .then((json) => ({ observation: json }))
        .catch(async (e) => ({ observation: null, error: await e }));
    },
    async create(data) {
      return http
        .post(`orders/${orderId}/obs`, data)
        .then((json) => ({ observation: json }))
        .catch(async (e) => ({ observation: null, error: await e }));
    },
    async update(data) {
      return http
        .put(`orders/${orderId}/obs`, obsId, data)
        .then((json) => ({ observation: json }))
        .catch(async (e) => ({ observation: null, error: await e }));
    },
    async delete() {
      return http
        .delete(`orders/${orderId}/obs`, obsId)
        .then((json) => ({ observation: json }))
        .catch(async (e) => ({ observation: null, error: await e }));
    },
  }),
  Logs: () => ({
    async index() {
      return http
        .index(`orders/${orderId}/logs`)
        .then((json) => ({ logs: json }))
        .catch(async (e) => ({ logs: null, error: await e }));
    },
  }),
  Payments: (paymentId) => ({
    async index(filters) {
      return http
        .index(`orders/${orderId}/payments`, filters)
        .then((json) => ({ payments: json }))
        .catch(async (e) => ({ payments: null, error: await e }));
    },
    async list(filters) {
      return http
        .index(`order_payments`, filters)
        .then((json) => ({ payments: json }))
        .catch(async (e) => ({ payments: null, error: await e }));
    },
    async get() {
      return http
        .get(`orders/${orderId}/payments`, paymentId)
        .then((json) => ({ payment: json }))
        .catch(async (e) => ({ payment: null, error: await e }));
    },
    async create(data) {
      return http
        .post(`orders/${orderId}/payments`, data)
        .then((json) => ({ payment: json }))
        .catch(async (e) => ({ payment: null, error: await e }));
    },
    async update(data) {
      return http
        .put(`orders/${orderId}/payments`, paymentId, data)
        .then((json) => ({ payment: json }))
        .catch(async (e) => ({ payment: null, error: await e }));
    },
    async delete() {
      return http
        .delete(`orders/${orderId}/payments`, paymentId)
        .then((json) => ({ payment: json }))
        .catch(async (e) => ({ payment: null, error: await e }));
    },
  }),
});
